import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
// import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Ice/login.vue');
const Users = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Ice/users.vue');
const InviteUser = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Ice/usersInvite.vue');
const ForgotPassword = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Ice/forgotPassword.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');
//Ice I80 Pages
//Settings
const Settings = () => import('src/pages/Ice/settings.vue')
//Partners
const Partners = () => import('src/pages/Ice/partners.vue')
const PartnersDetail = () => import('src/pages/Ice/partnersDetail.vue')
//Program
const Programs = () => import('src/pages/Ice/Programs/Programs.vue')
const ProgramsAdd = () => import('src/pages/Ice/Programs/CreateProgram/CreateProgram.vue')
const ProgramDetail = () => import('src/pages/Ice/Programs/ProgramDetail.vue')
const milestoneAdd = () => import('src/pages/Ice/milestoneAdd.vue')
//Startup
const StartupRegister = () => import('src/pages/Ice/Startups/RegisterStartup/RegisterStartup.vue')
const StartupDetail = () => import('src/pages/Ice/Startups/startupDetail.vue')
const startupEdit = () => import('src/pages/Ice/Startups/startupEdit.vue')
const memberAdd = () => import('src/pages/Ice/memberAdd.vue')
const ProductAdd = () => import('src/pages/Ice/productAdd.vue')
const ActionAdd = () => import('src/pages/Ice/actionAdd.vue')
//Edit Pages
const memberEdit = () => import('src/pages/IceEdit/memberEdit.vue')
const milestoneEdit = () => import('src/pages/IceEdit/milestoneEdit.vue')
const partnerEdit = () => import('src/pages/IceEdit/partnerEdit.vue')
const productEdit = () => import('src/pages/IceEdit/productEdit.vue')
const programEdit = () => import('src/pages/IceEdit/programEdit.vue')
const scorePoints = () => import('src/pages/Ice/scorePoints.vue')
let icePages = {
  path: '/',
  component: DashboardLayout,
  name: 'admin',
  redirect: '/',
  meta: {
    requiresAuth: false
  },
  children: [
    // Settings
    {
      path: 'settings',
      name: 'Settings',
      components: { default: Settings }
    },
    // Users
    {
      path: 'users',
      name: 'Users',
      components: { default: Users }
    },
    {
      path: 'users/invite/:program_id/',
      name: 'Invite User',
      component: InviteUser
    },
    // Partners
    {
      path: 'partners',
      name: 'Partners',
      components: { default: Partners }
    },
    {
      path: 'partners/detail/:partner_id',
      name: 'Partners-Detail',
      components: { default: PartnersDetail }
    },
    {
      path: 'partners/edit/:partner_id',
      name: 'Partners-Edit',
      components: { default: partnerEdit }
    },
    // Startups
    {
      path: 'startups/detail/:program_id/:startup_id',
      name: 'Startup information',
      components: { default: StartupDetail }
    },
    {
      path: 'startup/register/:program_id',
      name: 'Startup-Register',
      components: { default: StartupRegister }
    },
    {
      path: 'startup/edit/:program_id/:startup_id',
      name: 'Startup-Edit',
      components: { default: startupEdit }
    },
    {
      path: 'startup/actionAdd',
      name: 'Action-Add',
      components: { default: ActionAdd }
    },
    {
      path: 'startup/memberAdd',
      name: 'member-Add',
      components: { default: memberAdd }
    },
    {
      path: 'startupmember/edit/:program_id',
      name: 'member-Edit',
      components: { default: memberEdit }
    },
    {
      path: 'startup/productAdd',
      name: 'Product-Add',
      components: { default: ProductAdd }
    },
    {
      path: 'startupProduct/edit/:product_id',
      name: 'Product-Edit',
      components: { default: productEdit }
    },
    // Programs
    {
      path: 'programs/add',
      name: 'Programs-Add',
      components: { default: ProgramsAdd }
    },
    {
      path: 'programs/detail/:program_id',
      name: 'Program-Detail',
      components: { default: ProgramDetail }
    },
    {
      path: 'programs',
      name: 'Programs',
      components: { default: Programs }
    },
    {
      path: 'programs/edit/:program_id',
      name: 'Program-Edit',
      components: { default: programEdit }
    },
    {
      path: 'score-points',
      name: 'Create-Score-Points',
      components:{default: scorePoints}

    },
    {
      path: 'programs/milestoneAdd/:program_id',
      name: 'Milestone-Add',
      components: { default: milestoneAdd }
    },
    {
      path: 'programMilestone/edit/:program_id/:milestone_id',
      name: 'Program-Milestone-Edit',
      components: { default: milestoneEdit }
    },
  ]
};
let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    }
  ]
};
const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Home'
  },
  icePages,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard }
      }
    ]
  },
  // { path: '*', component: NotFound }
];
export default routes;
