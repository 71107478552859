<template>
  <div class="form-group" :class="{
    'input-group-focus': focused,
    'has-label': label,
    'has-icon': hasIcon,
  }">
    <slot name="label">
      <label :for="id" v-if="label"> {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0" :class="{ 'input-group': hasIcon }">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot>
        <input :value="value" v-bind="$attrs" v-on="listeners" v-if="!isTextArea" :name="label" :id="id" class="form-control"
          aria-describedby="addon-right addon-left" />
        <textarea :value="value" v-bind="$attrs" v-on="listeners" class="form-control" rows="3"
          aria-describedby="addon-right addon-left" v-if="isTextArea">
        </textarea>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text"><i :class="addonRightIcon"></i></div>
        </span>
      </slot>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <span class="error mt-2 mb-0 d-flex align-items-center"><i class="lni lni-xmark-circle mr-2"></i>{{ error }}</span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'base-input',
  props: {
    required: Boolean,
    isTextArea: Boolean,
    label: {
      type: String,
      description: 'Input label'
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false,
      id: Math.random().toString(36).substring(2, 8)
    }
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt.target.value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    }
  }
};
</script>
<style></style>
