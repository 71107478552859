//Old firebase
// export default {
//     apiKey: "AIzaSyCfjYhK7dnlhpkbTWlInspR25I7kYbb9HU",
//     authDomain: "ice180-platform.firebaseapp.com",
//     projectId: "ice180-platform",
//     storageBucket: "ice180-platform.appspot.com",
//     messagingSenderId: "883846737151",
//     appId: "1:883846737151:web:cb79406f7ad85d7fe4b0ca",
//     measurementId: "G-BY0LFD3M9H"
// }
  const production = {
    apiKey: "AIzaSyC2Fzu9iq0f7eCa9d3lYhafKXvxsS_W7ck",
    authDomain: "ice180.firebaseapp.com",
    databaseURL: "https://ice180-default-rtdb.firebaseio.com",
    projectId: "ice180",
    storageBucket: "gs://ice180.appspot.com",
    messagingSenderId: "191902005095",
    appId: "1:191902005095:web:44fb56157fbf6eb39161c2",
    measurementId: "G-0PJ870F80Q"
  }
  let firebaseConfig = production;
  export default firebaseConfig;
  // Production Build
/*
  cp firebaseProdConfig.js firebaseConfig.js
  npm run build
  firebase use production
  firebase deploy --only hosting:ice180,function  firebase deploy --only hosting:ice180,functionss
  */
