var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('user-menu'),_c('sidebar-item',{attrs:{"link":{
  name: 'Dashboard',
  icon: 'lni lni-dashboard-square-1',
  path: '/dashboard',
}}}),_c('sidebar-item',{attrs:{"link":{
  name: 'Programs',
  icon: 'lni lni-bricks',
  path: '/programs',
}}}),(_vm.type == 1)?_c('sidebar-item',{attrs:{"link":{
  name: 'Users',
  icon: 'lni  lni-hierarchy-1',
  path: '/users',
}}}):_vm._e()],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar',{staticClass:"dash-nav"}),_c('router-view',{attrs:{"name":"header"}}),_c('div',{staticClass:"container p-0 py-5",class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }