// store/index.js
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth"
import user from "./modules/user"
import startup from "./modules/startup"
import program from './modules/program';
import partner from './modules/partner';
// import { collection, query, where, getDocs } from "firebase/firestore";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules:{
    auth,
    user,
    startup,
    program,
    partner
  },
  state: {
  },
  mutations: {},
  getters: {},
  actions: {},
});
