<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <!-- <sidebar-fixed-toggle-button /> -->
    <side-bar :background-color="sidebarBackground" :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')">
      <template slot="links">
        <user-menu></user-menu>
        <sidebar-item :link="{
    name: 'Dashboard',
    icon: 'lni lni-dashboard-square-1',
    path: '/dashboard',
  }">
        </sidebar-item>
        <!-- <sidebar-item
          v-if="false"
          :link="{
            name: 'Startups',
            icon: 'lni lni-bulb',
            path: '/startups',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          v-if="type != 3"
          :link="{
            name: 'Partners',
            icon: 'lni lni-users',
            path: '/partners',
          }"
        ></sidebar-item> -->
        <sidebar-item :link="{
    name: 'Programs',
    icon: 'lni lni-bricks',
    path: '/programs',
  }"></sidebar-item>
        <sidebar-item v-if="type == 1" :link="{
    name: 'Users',
    icon: 'lni  lni-hierarchy-1',
    path: '/users',
  }"></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'Invite',
            icon: 'lni lni-envelope-1',
            path: '/invite',
          }"
        ></sidebar-item> -->
        <!-- <sidebar-item
          v-if="type == 1"
          :link="{
            name: 'Add new user',
            icon: 'fas fa-plus',
            path: '/invite',
          }"
        ></sidebar-item> -->
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color="sidebarBackground"> </sidebar-share> -->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar class="dash-nav"></dashboard-navbar>
      <router-view name="header"></router-view>
      <div class="container p-0 py-5" :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
import { mapGetters } from "vuex";
import DashboardNavbar from './DashboardNavbar.vue';
import UserMenu from "./Extra/UserMenu.vue";
export default {
  components: {
    ZoomCenterTransition,
    UserMenu,
    DashboardNavbar,
  },
  data() {
    return {
      sidebarBackground: "red", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    ...mapGetters({
      type: "auth/type",
    }),
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
.content {
  overflow: hidden;
}
.sidebar-wrapper {
  display: grid;
  padding: 2em 0em;
  grid-template-rows: 1fr 13fr;
  padding-left: 2em;
  div.logo {
    padding: 0px !important;
    display: flex;
    justify-content: flex-start;
    img {
      border-radius: 0px;
      height: 1.8em;
      &::after {
        display: none;
      }
    }
  }
  ul.nav {
    div.user {
      div.photo {
        margin: 0px;
      }
      div.info {
        a {
          b.caret {
            top: 12px;
            right: 0px;
          }
          span {
            &.lni {
              font-size: 1rem;
            }
          }
        }
      }
    }
    li {
      a {
        margin-left: 0px;
        padding-left: 0px;
        i {
          background-color: rgba(9, 25, 40, 0.07);
          border-radius: 0.625rem;
        }
      }
      a.router-link-exact-active.active {
        i {
          background-color: rgb(242, 71, 66);
          color: white !important;
        }
        p {
          font-weight: 600;
        }
      }
    }
  }
}
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
.hr-white {
  border: 0.5px dotted #fff;
}
.white-content .sidebar p {
  color: #253341;
}
.sidebar .nav li>a:not([data-toggle="collapse"]) i,
.sidebar .nav li>a:not([data-toggle="collapse"]) p,
.off-canvas-sidebar .nav li>a:not([data-toggle="collapse"]) i,
.off-canvas-sidebar .nav li>a:not([data-toggle="collapse"]) p {
  color: #253341 !important;
}
.sidebar .nav li.active>a:not([data-toggle="collapse"]) i,
.sidebar .nav li.active>a:not([data-toggle="collapse"]) p,
.off-canvas-sidebar .nav li.active>a:not([data-toggle="collapse"]) i,
.off-canvas-sidebar .nav li.active>a:not([data-toggle="collapse"]) p {
  color: #f24742 !important;
}
.sidebar .nav li.active>a:not([data-toggle="collapse"]) i,
.sidebar .nav li.active>a:not([data-toggle="collapse"]) p,
.off-canvas-sidebar .nav li.active>a:not([data-toggle="collapse"]) i,
.off-canvas-sidebar .nav li.active>a:not([data-toggle="collapse"]) p {
  color: #000;
}
.sidebar .nav li:hover:not(.active)>a p,
.sidebar .nav li:hover:not(.active)>a i,
.sidebar .nav li:focus:not(.active)>a p,
.sidebar .nav li:focus:not(.active)>a i,
.off-canvas-sidebar .nav li:hover:not(.active)>a p,
.off-canvas-sidebar .nav li:hover:not(.active)>a i,
.off-canvas-sidebar .nav li:focus:not(.active)>a p,
.off-canvas-sidebar .nav li:focus:not(.active)>a i {
  color: #253341;
}
.sidebar,
.off-canvas-sidebar {
  height: calc(100vh) !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  border-radius: 0px;
}
.main-panel[data="red"] {
  border-top: 0px solid #ec250d !important;
}
.nav-open .main-panel {
  right: 0;
  -webkit-transform: translate3d(260px, 0, 0);
  transform: translate3d(260px, 0, 0);
}
// @media (min-width: 1200px) {
//   .dash-nav {
//     display: none;
//   }
//   .main-panel > .content {
//     padding: 5em 0em;
//     margin-left: 235px;
//     min-height: 100vh;
//   }
// }</style>
