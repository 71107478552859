export default class StartupModel {
    constructor({
      id = null,
      name,
      program,
      programID = "",
      partners = [],
      type,
      creationTime = "",
      description,
      address,
      employees = null,
      sector = null,
      programDetails = null,
      contactInfo = null,
      socialMediaLinks = null,
      additionalQuestionsWithAnswers = null,
      members = 1,
      city,
      website,
      email,
      phone,
      stage,
      jobs,
      valuation,
      initialCap,
      profits,
      assets,
      totalMilestones= 0,
      completedMilestones= 0,
      performanceDataPointsWithValues = {},
    }) {
      this.id = id,
      this.name = name,
      this.program = program,
      this.programID= programID,
      this.partners= partners,
      this.type= type,
      this.creationTime= creationTime,
      this.description= description,
      this.address= address,
      this.employees= employees,
      this.sector= sector,
      this.programDetails= programDetails,
      this.socialMediaLinks= socialMediaLinks,
      this.additionalQuestionsWithAnswers= additionalQuestionsWithAnswers,
      this.contactInfo= contactInfo,
      this.members= members,
      this.city= city,
      this.website= website,
      this.email= email,
      this.phone= phone,
      this.stage= stage,
      this.jobs= jobs,
      this.valuation= valuation,
      this.initialCap= initialCap,
      this.profits= profits,
      this.assets= assets,  
      this.completedMilestones = completedMilestones;
      this.performanceDataPointsWithValues = performanceDataPointsWithValues;
    }
  }