import db from "@/components/Firebase/firebaseInit";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async deleteStartup(
      {},
      payload = {
        programId: null,
        startupId: null,
      }
    ) {
      try {
        if (payload.programId === null || payload.startupId === null) {
          throw "Payload invalid";
        }

        const startupRef = doc(
          db,
          `programs/${payload.programId}/startups/`,
          payload.startupId
        );
        await deleteDoc(startupRef);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    calculateStartupPerformance(
      {},
      payload = {
        evaluationData: null,
        percentage: true,
      }
    ) {
      if (!payload.evaluationData) {
        return 0;
      }
      const values = Object.values(payload.evaluationData)
        .map((value) => parseFloat(value))
        .filter((value) => value > 0);
      let performance = values.reduce((accumulator, current) => {
        return accumulator + current;
      }, 0);
      performance = performance / values.length;
      if (payload.percentage === false) {
        return Math.floor(performance);
      }
      performance = performance / 5;
      performance = performance * 100;
      return Math.floor(performance);
    },
    cummulateStartupEvaluations(
      {},
      payload = { evaluations: null, labels: null }
    ) {
      if (!payload.evaluations || !payload.labels) {
        return null;
      }
      else if(payload.evaluations.length < 1){
        return null;
      }
      let total = payload.evaluations
        .map((evaluation) => evaluation.data)
        .reduce((accumulator, current) => {
          let reduce = new Map();
          payload.labels.map((key) => {
            let accumulatedValue = parseFloat(
              accumulator != undefined && accumulator[key] != undefined
                ? accumulator[key]
                : "0"
            );
            let currentValue = parseFloat(
              current != undefined ? current[key] : "0"
            );
            if (accumulatedValue > 0) {
              if (currentValue < 1) {
                reduce.set(key, accumulatedValue.toFixed(2));
              } else {
                reduce.set(key, currentValue.toFixed(2));
              }
            } else {
              reduce.set(key, (accumulatedValue + currentValue).toFixed(2));
            }
          });
          return Object.fromEntries(reduce.entries());
        });
      let totalEvaluation = {
        data: total,
        evaluatorEmail: "System",
        evaluatorType: "Cummulative performance evaluation",
        timestamp: new Date().getTime(),
      };
      return totalEvaluation;
    },
    meanStartupEvaluations({}, payload = { evaluations: null, labels: null }) {
      if (!payload.evaluations || !payload.labels) {
        return null;
      }
      if (payload.evaluations.length === 0) {
        return null;
      }
      console.log(payload);
      const mean = new Map();
      const evalData = payload.evaluations.map((evaluation) => evaluation.data);
      payload.labels.forEach((key) => {
        const values = evalData
          .map((evaluation) => {
            if (evaluation[key] !== undefined) {
              return parseFloat(evaluation[key]);
            } else {
              return 0;
            }
          })
          .filter((value) => value != 0);
        const value = (
          values.reduce((a, b) => a + b, 0) / values.length
        ).toFixed(2);
        mean.set(key, value);
      });
      let meanEvaluation = {
        data: Object.fromEntries(mean.entries()),
        evaluatorEmail: "System",
        evaluatorType: "Average performance of startups",
        timestamp: new Date().getTime(),
      };
      return meanEvaluation;
    },
    async addStartup(
      { dispatch },
      payload = { startup: null, programId: null, credentialEmail: null }
    ) {
      try {
        let startupRef = doc(
          db,
          "programs",
          `${payload.programId}/startups/${payload.startup.id}`
        );
        await setDoc(startupRef, payload.startup);
        const userDetails = await dispatch(
          "user/getUser",
          payload.credentialEmail,
          { root: true }
        );
        userDetails.userId = payload.startup.id;
        userDetails.programs = [payload.programId];
        return dispatch("user/updateUserData", userDetails, { root: true });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    generateStartUpId({}, startupName) {
      let id = Math.random().toString(36).substring(2, 8);
      id = startupName.slice(0, 2).toUpperCase() + "-" + id;
      return id;
    },
    async inviteStartup({}, emailAddress, programId) {
      try {
        const actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be in the authorized domains list in the Firebase Console.
          url: `http://localhost:8080/#/startup/invite/${programId}`,
          handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem("emailForSignIn", email);
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(error);
        throw error;
      }
    },
    async uploadFile({}, payload) {
      try {
        const storage = getStorage();
        const path = `${payload.startupId}/${payload.milestoneId}/${payload.file.name}`;
        const storageRef = ref(storage, path);
        await uploadBytes(storageRef, payload.file);
        const url = await getDownloadURL(storageRef);
        return url;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async setStartupMilestones({}, payload) {
      try {
        const startupRef = doc(
          db,
          `programs/${payload.programId}/startups/`,
          payload.startupId
        );
        return await updateDoc(startupRef, {
          completedMilestones: payload.completedMilestones,
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async setPerformanceData({}, payload) {
      try {
        const startupRef = doc(
          db,
          `programs/${payload.programId}/startups/`,
          payload.startupId
        );
        return await updateDoc(startupRef, {
          performanceDataPointsWithValues:
            payload.performanceDataPointsWithValues,
        });
      } catch (error) {
        throw error;
      }
    },
    async editStartup({}, payload) {
      try {
        const startupRef = doc(
          db,
          `programs/${payload.programId}/startups/`,
          payload.startupId
        );
        const startup = payload.startup;
        const startupData = {
          name: startup.name,
          sector: startup.sector,
          stage: startup.stage,
          businessRegistrationDate: startup.businessRegistrationDate,
          annualRevenue: startup.annualRevenue,
          contactInfo: startup.contactInfo,
          socialMediaLinks: startup.socialMediaLinks,
          additionalQuestionsWithAnswers:
            startup.additionalQuestionsWithAnswers,
          description: startup.description,
        };
        return await updateDoc(startupRef, { ...startupData });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  getters: {
    getAllStartupCountForProgram: (programId) => {
      return async (programId) => {
        const startupsSnapshot = await db
          .collection("programs")
          .doc(programId)
          .collection("startups")
          .get();
        return startupsSnapshot.size;
      };
    },
    getAssociatedStartupsByEmail: (state) => {
      let startups = [];
      // TODO: implement.
    },
    getAllStartupsForProgram: () => async (programId) => {
      try {
        const querySnapshot = await getDocs(
          collection(db, `programs/${programId}/startups`)
        );
        if (querySnapshot.size > 0) {
          return querySnapshot.docs.map((doc) => doc.data());
        } else {
          return [];
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    getStartup: () => async (programId, startupId) => {
      try {
        const startupRef = doc(
          db,
          `programs/${programId}/startups/`,
          startupId
        );
        const startup = await getDoc(startupRef);
        if (startup.exists) {
          return startup.data();
        } else {
          throw { message: "Startup not found" };
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    getAllStartups() {
      let startups = [];
      // QUESTION: add program id by reading parent doc OR add program_id when creading startup as field
      return new Promise((resolve, reject) => {
        db.collectionGroup("startups")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              let program = await doc.ref.parent.parent.get();
              const startup = {
                ...doc.data(),
                id: doc.id,
                programId: program.id,
              };
              startups.push(startup);
            });
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            resolve(startups);
          });
      });
    },
  },
};
