import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithEmailLink,
} from "firebase/auth";
import { ref } from "vue";
const auth = getAuth();
export default {
  namespaced: true,
  state: {
    models: {
      globalError: "",
      userData: null,
      guest: false,
      type: null,
      email: null,
      id: null,
      loggedIn: false,
    },
    userCredential: null,
    authTypesSelect: new ref([
      { id: 1, label: "Admin", value: "admin" },
      { id: 2, label: "Program Manager", value: "program_manager" },
      { id: 3, label: "Incubation Manager", value: "incubation_manager" },
      { id: 4, label: "Startup", value: "startup" },
      { id: 5, label: "Partner", value: "partner" },
    ]),
    user: null,
  },
  mutations: {
    login(state, payload) {
      state.models.loggedIn = true;
      state.models.type = payload.type;
      state.models.email = payload.email;
      state.models.id = payload.id;
      state.userCredential = payload.userCredential;
      state.user = payload.user;
    },
    logout(state) {
      state.models.loggedIn = false;
      state.models.email = null;
      state.models.globalError = "";
      state.models.type = null;
      state.models.id = null;
      state.userCredential = null;
      state.user = null;
    },
    updateUser(state, payload) {
      state.models.user = payload.user;
    },
  },
  actions: {
    async allowedAuthTypesSelect({ state, getters }) {
      try {
        if (state != null && state.models != null && state.models.loggedIn) {
          let currentType = await getters.type;
          let allTypesSelect = [
            { id: 1, label: "Admin", value: "admin" },
            { id: 2, label: "Program Manager", value: "program_manager" },
            { id: 3, label: "Incubation Manager", value: "incubation_manager" },
            { id: 4, label: "Startup", value: "startup" },
            { id: 5, label: "Partner", value: "partner" },
          ];
          let allowedAuthTypesSelect = [];
          for (
            let index = currentType;
            index < allTypesSelect.length;
            index++
          ) {
            allowedAuthTypesSelect.push(allTypesSelect[index]);
          }
          return new ref(allowedAuthTypesSelect);
        }
      } catch (error) {
        console.warn(error);
      }
    },
    async userLogOut({ commit }) {
      try {
        await auth.signOut();
        commit("logout");
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async userLogin({ commit, dispatch }, payload) {
      try {
        const registeredUser = await dispatch("user/getUser", payload.email, {
          root: true,
        });
        if (registeredUser) {
          await setPersistence(auth, browserLocalPersistence);
          const authUser = await signInWithEmailAndPassword(
            auth,
            payload.email,
            payload.password
          );
          if (authUser.user != null) {
            commit("login", {
              type: registeredUser.type,
              email: registeredUser.email,
              id: registeredUser.id,
              userCredential: authUser,
              user: registeredUser,
            });
          } else {
            throw { message: "Invalid Credentials" };
          }
        } else {
          throw { message: "This Email is not found" };
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createUserAuth({ dispatch }, credentials) {
      try {
        const userAuthExists = await dispatch(
          "user/userExists",
          credentials.email,
          {
            root: true,
          }
        );
        if (!userAuthExists) {
          const createdUser = await createUserWithEmailAndPassword(
            auth,
            credentials.email,
            credentials.password
          );
          if (createdUser.user == null) {
            throw { message: " User creation failed" };
          }
        } else {
          throw { message: " Email Account Already in use" };
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async sendSignInLinkToEmail({}, email) {
      try {
        const actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be in the authorized domains list in the Firebase Console.
          // url: "http://localhost:8080/#/login",
          url: "https://ice180.com/#/login/",
          handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem("emailForSignIn", email);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async sendPasswordReset({}, email) {
      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        console.error(error);
      }
    },
    async tryLoginLink({ dispatch, commit }) {
      try {
        let urlParams = window.location.search;
        if (urlParams.includes("mode=signIn")) {
          const newUrl = `${window.location.origin}/#/${window.location.search}`;
          window.location.href = newUrl;
          return false;
        }
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem("emailForSignIn");
          if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt("Please provide your email for confirmation");
          }
          console.warn(email);
          if (email != null) {
            const registeredUser = await dispatch("user/getUser", email, {
              root: true,
            });
            if (registeredUser != null) {
              const user = await signInWithEmailLink(
                auth,
                email,
                window.location.href
              );
              if (user != null) {
                commit("login", {
                  type: registeredUser.type,
                  email: registeredUser.email,
                  id: registeredUser.id,
                  userCredential: user,
                  user: registeredUser,
                });
                window.localStorage.removeItem("emailForSignIn");
                return user;
              }
              throw {
                message: "Sign in failed, please contact the administrator",
              };
            }
            throw {
              message: "User not registered, please contact the administrator",
            };
          }
          return false;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error.code === "auth/invalid-email");
        if (error.code === "auth/invalid-email") {
          window.localStorage.removeItem("emailForSignIn");
        }
        console.table(error);
        throw error;
      }
    },
    async currentUser({ state }) {
      if (state.models.loggedIn) {
        if (state.user != null) {
          return state.user;
        } else {
          return false;
        }
      }
      return false;
    },
  },
  getters: {
    type(state) {
      switch (state.models.type) {
        case "admin":
          return 1;
        case "program_manager":
          return 2;
        case "incubation_manager":
          return 3;
        case "startup":
          return 4;
        case "partner":
          return 5;
        default:
          return 4;
      }
    },
    readableType(state) {
      switch (state.models.type) {
        case "admin":
          return "System Administrator";
        case "program_manager":
          return "Program Manager";
        case "incubation_manager":
          return "Incubation Manager";
        case "startup":
          return "Startup";
        case "partner":
          return "Partner";
        default:
          return state.models.type;
      }
    },
    authUser(state) {
      return state.models;
    },
  },
};
