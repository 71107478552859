/* eslint-disable no-console */
const clearCache = (worker) => {
  // cache busting: Reliable but modifies URL
  console.log("skip waiting ");
  console.log(worker);
  worker.postMessage({ action: "skipWaiting" });
  // if (window.confirm(`New update is available`)) {
  // }
};
var refreshing;
window.navigator.serviceWorker.addEventListener(
  "controllerchange",
  function () {
    console.log("controller changed");
    if (refreshing) return;
    refreshing = true;
    // window.location.reload();
  }
);
// TODO: PWA enable service workers after cache issue is resolved.
// if (process.env.NODE_ENV === "production") {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     registrationOptions: {
//       scope: process.env.BASE_URL,
//       updateViaCache: "none",
//     },
//     ready() {
//       console.log(
//         "App is being served from cache by a service worker.\n" +
//           "For more details, visit https://goo.gl/AFskqB"
//       );
//     },
//     registered() {
//       console.log("Service worker has been registered.");
//     },
//     cached() {
//       console.log("Content has been cached for offline use.");
//     },
//     updatefound(registration) {
//       console.log("New content is downloading.");
//       console.log(registration.waiting);
//     },
//     updated(registration) {
//       console.log("New content is available; please refresh.");
//       console.log(registration);
//       clearCache(registration.waiting);
//       // console.log(registration);
//       // if (window.confirm("New update is available")) {
//       // } else {
//       //   clearCache();
//       // }
//     },
//     offline() {
//       console.log(
//         "No internet connection found. App is running in offline mode."
//       );
//     },
//     error(error) {
//       console.error("Error during service worker registration:", error);
//     },
//   });
// }
