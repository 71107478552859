<template>
  <div class="user user__menu">
    <div class="photo">
      <img :src="image" alt="avatar" />
    </div>
    <a
      data-toggle="collapse"
      :aria-expanded="!isClosed"
      @click.stop="toggleMenu"
      href="#"
    >
      <span>
        {{ this.title }}
        <b class="fa fa-chevron-down"></b>
      </span>
      <h6 class="primary">{{ this.formattedType }}</h6>
    </a>
    <div class="info">
      <div>
        <collapse-transition>
          <ul class="nav user-menu__nav" v-show="!isClosed">
            <slot>
              <li>
                <a href="/#/settings">
                  <span class="lni lni-cog mr-2"></span>
                  <span class="sidebar-normal">Settings</span>
                </a>
              </li>
              <li>
                <a href="#" v-on:click="logout">
                  <span class="lni lni-exit mr-2"></span>
                  <span class="sidebar-normal">Logout</span>
                </a>
              </li>
            </slot>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "user-menu",
  components: {
    CollapseTransition,
  },
  data() {
    return {
      isClosed: true,
    };
  },
  computed: {
    ...mapGetters({type: "auth/type",
    formattedType: "auth/readableType"
  }),
    image() {
      return "img/placeholder.jpg";
    },
    title() {
      let authEmail = this.$store.state.auth.models.email;
      if (authEmail) {
        authEmail =
          authEmail.length > 14
            ? authEmail.substring(0, 14) + "..."
            : authEmail;
      }
      return authEmail;
    },
    // formattedType() {
    //   let type = this.type;
    //   switch (type) {
    //     case 1:
    //       return "System Administrator";
    //     case 2:
    //       return "Program Manager";
    //     case 3:
    //       return "Incubation Manager";
    //     case 4:
    //       return "Startup";
    //     case 5:
    //       return "Partner";
    //     default:
    //       return type;
    //   }
    // },
  },
  methods: {
    ...mapActions({ userLogOut: "auth/userLogOut" }),
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    async logout() {
      await this.userLogOut().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style lang="scss">
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
.sidebar,
.off-canvas-sidebar {
  .user a {
    color: #000 !important;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    &[aria-expanded] {
      b {
        &::before {
          content: "\f077";
        }
      }
    }
  }
}
.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal {
  color: #253341 !important;
}
.sidebar .user:after,
.off-canvas-sidebar .user:after {
  background: #f5f6f6 !important;
}
.sidebar .user .photo,
.off-canvas-sidebar .user .photo {
  -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 30%) !important;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 30%) !important;
}
.sidebar .nav li:first-child > a,
.off-canvas-sidebar .nav li:first-child > a {
  margin: 0px !important;
}
</style>
