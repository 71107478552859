<template>
  <router-view></router-view>
</template>
<script>
import db from "@/components/Firebase/firebaseInit";
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
  },
  mounted() {
    // intializing database
    const database = db;
    if (database) {
    }
    this.initializeLayout();
  },
};
</script>
