import db from "@/components/Firebase/firebaseInit";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
  },
  getters: {
    async getAllPartners() {
      // TODO: Convert to standard Promise return concept
      let allPartners = [];
      return await db
        .collection("partners")
        .get()
        .then((partners) => {
          partners.forEach((partner) => {
            const _partner = {
                logo: partner.data().logo,
                id: partner.id,
                title: partner.data().name,
                subTitle: partner.data().email,
            };
            allPartners.push(_partner);
          });
        })
        .then(() => {
          return allPartners;
        });
    },
    getMatchingPartners: state => async (email) => {
      let allPartners = [];
      return new Promise((resolve, reject) => {
        db.collection("partners")
        // QUESTION: are partners associated with email of logged in user?
        .where("email", "==", email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let partnerID = doc.id;
            const partnerData = {
              id: doc.id,
              logo: doc.data().logo,
              programs: []
            };
            //TODO: get programs under partners
            db.collection("partners").doc(partnerID).collection("programs").get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  partnerData.programs.push(doc.id);
                });
              });
            });
            allPartners.push(partnerData);
        }).then(() => {
          resolve(allPartners)
        });
      });
    },
  },
};
